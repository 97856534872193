import { Fragment } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { ADVANCED_VISUALISATIONS } from '_shared/globalState/atoms';
import ContentComponent from 'content/ContentComponent';

const ContentGrid = ({ content, objectVariable, objectId, video }) => {
  // minH="100%" templateRows='repeat(auto-fit, 500px)'
  const visualisations = useRecoilValue(ADVANCED_VISUALISATIONS);

  if (!content) return null;
  return (
    <SimpleGrid templateColumns="repeat(12, 1fr)" spacing="5" p="5">
      {content
        .filter(({ content_type }) => {
          if (!['pitchmap', 'wagon_wheel', 'beehive'].includes(content_type)) {
            return true;
          }
          if (visualisations && visualisations.includes(content_type)) return true;
          return false;
        })
        .map((el, idx) => {
          return (
            <Fragment key={`content_${idx}`}>
              <ContentComponent
                contentData={el}
                objectVariable={objectVariable}
                objectId={objectId}
                video={video}
              />
              { optionalOrganizeByComponent(el, idx) }
            </Fragment>
          );
        })}
    </SimpleGrid>
  );
};

const optionalOrganizeByComponent = (el, idx) => {
  let retVal = null;

  if (el?.content_type === 'table') {

    const organizeBy = el?.data?.table_header?.find((tableHeaderItem) => tableHeaderItem.organise_by);

    if (organizeBy) {

      const {label} = organizeBy;
      const options = el?.data?.table_data?.map((tableDataItem) => {
        const value = tableDataItem[organizeBy?.key];
        // Data can be either 'string' or 'number' type
        const labelValue = tableDataItem[organizeBy?.key] != null ? `${tableDataItem[organizeBy?.key]}` : '';
        return {
          value,
          label: labelValue.charAt(0).toUpperCase() + labelValue.slice(1)
        };
      });

      const contentData = {
        content_type: `${el.content_type}_organize_by`,
        key: `${el.key}_organize_by`,
        data: {
          label,
          options
        }
      };

      retVal = (
        <ContentComponent
          contentData={contentData}
          key={`organizeBy_${idx}`}
        />
      );
    }
  }

  return retVal;
};

export default ContentGrid;
