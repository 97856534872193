import { atom } from 'recoil';
import { localStorageEffect } from './atomEffects';

export const PANEL_SETTINGS_OPEN = atom({
  key: 'panelSettingsOpen',
  default: false
});

export const HIDE_SHOW_OPEN = atom({
  key: 'hideShowOpen',
  default: false
});

export const PANEL_SETTINGS_AVAILABLE = atom({
  key: 'panelSettingsAvailable',
  default: false
});

export const HIDDEN_PANELS = atom({
  key: 'hiddenPanels',
  default: {}
});

export const LAYOUT_DEFS = atom({
  key: 'layoutDefs',
  default: {}
});

export const PANEL_CATEGORIES = atom({
  key: 'panelCategories',
  default: []
});
export const FOCUS_PANEL = atom({
  key: 'focusPanel',
  default: null
});

export const SCORECARD_TYPE = atom({
  key: 'scorecardType',
  default: 'full'
});

export const MATCH_VIDEO_ENABLED = atom({
  key: 'matchVideoEnabled',
  default: false
});

export const MATCH_VIDEO_ID = atom({
  key: 'bbbVideoId',
  default: 0
});

export const SCROLL_DIRECTION = atom({
  key: 'scrollDirection',
  default: null
});

export const HEADER_EXPANDED = atom({
  key: 'headerExpanded',
  default: { player: false, team: false }
});

export const USER_CONFIG_MODAL = atom({
  key: 'userConfigModal',
  default: false
});

export const USER_FEEDBACK_SETTINGS = atom({
  key: 'userFeedbackSettings',
  default: {}
});

export const PAGE_FILTER_DATES = atom({
  key: 'pageFilterDates',
  default: null
});

export const GLOBAL_NAV_OPEN = atom({
  key: 'globalNavOpen',
  default: false
});

export const VIDEO_MODAL = atom({
  key: 'videoModal',
  default: false
  // VALUES:
  //    false
  //      - modal not shown (default)
  //    { content: string, direct: boolean }
  //      - modal shown with 'content', where 'content' can be either direct from a full URL, or require fetch first (depending on 'direct' param).
});

export const BETA_SELECT = atom({
  key: 'betaSelect',
  default: false,
  effects: [localStorageEffect('betaSelect')]
});

export const ADVANCED_VISUALISATIONS = atom({
  key: 'advancedVisualisations',
  default: ['wagon_wheel', 'beehive', 'pitchmap'],
  effects: [localStorageEffect('advancedVisualisations')]
});

export const VIEW_BY = atom({
  key: 'viewBy',
  default: []
});
