import TableContent from './table/TableContent';
import TableOrganizeBy from './table/TableOrganizeBy';
import StatsContent from './stats/StatsContent';
import PlayerSkillsContent from './playerSkills/PlayerSkillsContent';
import MatchupContent from './matchup/MatchupContent';
import CaptaincyGridContent from './match/captaincy-grid/CaptaincyGridContent';
import BeehiveViewContent from './widgets/BeehiveViewContent';
import PitchmapViewContent from './widgets/PitchmapViewContent';
import WagonwheelViewContent from './widgets/WagonwheelViewContent';
import ManhattanContent from './widgets/ManhattanContent';
import WinVizHistoryContent from './widgets/WinVizHistoryContent';
import ScorecardViewContent from './widgets/ScorecardViewContent';
import CommentaryViewContent from './widgets/CommentaryViewContent';
import PreMatchConditions from './match/preMatchConditions/PreMatchConditions';
import HeadToHead from './match/headToHead/HeadtoHead';
import RecentPerformance from './match/recentPerformance/RecentPerformance';
import CompTable from './comp/compTable/CompTable';
import MatchList from './matchList/MatchList';
import RankingContent from './ranking/RankingContent';
import MatchSquadContent from './match/MatchSquadContent';
import SquadContent from './squad/SquadContent';

const ContentTypeDefinitions = {
  table: TableContent,
  table_organize_by: TableOrganizeBy,
  general_stats: StatsContent,
  bar: StatsContent,
  player_skills: PlayerSkillsContent,
  player_matchup_grid: MatchupContent,
  captaincy_grid: CaptaincyGridContent,
  beehive: BeehiveViewContent,
  pitchmap: PitchmapViewContent,
  wagon_wheel: WagonwheelViewContent,
  winviz_history: WinVizHistoryContent,
  manhattan: ManhattanContent,
  scorecard: ScorecardViewContent,
  ball_by_ball: CommentaryViewContent,
  squad_list: MatchSquadContent,
  team_head_to_head: HeadToHead,
  team_recent_performance: RecentPerformance,
  match_conditions: PreMatchConditions,
  comp_table: CompTable,
  match_list: MatchList,
  comp_squads: SquadContent,
  player_ranking: RankingContent,
  team_ranking: RankingContent,
  ranking: RankingContent,
  team_squads: SquadContent
};

export default ContentTypeDefinitions;
