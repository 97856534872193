import { Box, Text } from '@chakra-ui/react';
import isEqual from 'lodash.isequal';
import { memo } from 'react';
import { useRecoilState } from 'recoil';
import { VIEW_BY } from '_shared/globalState/atoms';
import FilterMultiSelect from '../../pages/_shared/components/AdvancedFilters/FilterElements/FilterMultiSelect';

const TableOrganizeBy = ({ content }) => {
  const [viewBy, setViewBy] = useRecoilState(VIEW_BY);
  const { data } = content;
  const { label, options } = data;
  const name = 'multiSelectKey';

  const handleFilterChange = (_, value) => {
    if (!isEqual(viewBy, value)) {
      setViewBy(value);
    }
  };

  return (
    <Box px="4" py="2" display="flex" alignItems="center" columnGap="4">

      <Text whiteSpace="nowrap">
        View by:
      </Text>

      <FilterMultiSelect
        options={options}
        labelPrefix=""
        label={label}
        handleFilterChange={handleFilterChange}
        name={name}
        filterValues={{multiSelectKey: viewBy}}
      />

    </Box>
  );
};

const memoFunction = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default memo(TableOrganizeBy, memoFunction);
