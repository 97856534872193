import { Box, Flex, Input } from '@chakra-ui/react';
import WinVizPanel from 'panels/PanelTypes/WinVizPanel';
import { useState } from 'react';

const WinVizTestRoute = () => {
  const [winVizAmount, setWinVizAmount] = useState({ team1: 95, team2: 5 });

  const setPercent = (value, type) => {
    setWinVizAmount((p) => {
      const newValue = parseInt(value) > 100 ? 100 : parseInt(value);
      if (type === 'team1') {
        const otherValue = newValue + p.team2 > 100 ? 100 - newValue : p.team2;
        return { team1: newValue, team2: otherValue };
      }
      if (type === 'team2') {
        const otherValue = newValue + p.team1 > 100 ? 100 - newValue : p.team1;
        return { team2: newValue, team1: otherValue };
      }
    });
  };
  return (
    <Flex bg="black.8" direction="column" alignItems="center" h="100%" gap="4">
      <Flex gap="4">
        <Box>
          <Box>Eng %</Box>
          <Input
            type="number"
            size="sm"
            value={winVizAmount.team1}
            onChange={(e) => setPercent(e.target.value, 'team1')}
          />
        </Box>
        <Box>
          <Box>Aus %</Box>
          <Input
            type="number"
            size="sm"
            value={winVizAmount.team2}
            onChange={(e) => setPercent(e.target.value, 'team2')}
          />
        </Box>
      </Flex>
      <Box w="500px" h="218px" bg="white.100">
        <WinVizPanel
          panelData={{
            data: [
              {
                team1_logo: 'https://img.cricviz.com/logos/teams/England_2x.png',
                team1_name: 'England',
                team1_abbreviation: 'ENG',
                team1_percent: winVizAmount.team1,
                team1_primary_colour: '023B7A',
                // team1_secondary_colour,
                team2_logo: 'https://img.cricviz.com/logos/teams/Australia_2x.png',
                team2_name: 'Austrailia',
                team2_abbreviation: 'AUS',
                team2_percent: winVizAmount.team2,
                team2_primary_colour: 'F7DA0C',
                // team2_secondary_colour,
                draw_percent: 100 - winVizAmount.team1 - winVizAmount.team2,
                tie_percent: 0
              }
            ]
          }}
        />
      </Box>
    </Flex>
  );
};

export default WinVizTestRoute;
