import { Box, Flex, Image, Circle, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';

const calcWVColors = (data, showTeamColors) => {
  if (showTeamColors) {
    const { team1_primary_colour, team2_primary_colour } = data;
    return { team1Color: `#${team1_primary_colour}`, team2Color: `#${team2_primary_colour}` };
  }
  const { team1_percent, team2_percent } = data;
  if (team1_percent === team2_percent) {
    return { team1Color: 'positive.tint.30', team2Color: 'positive.tint.30' };
  }
  if (team1_percent > team2_percent) {
    return { team1Color: 'positive.main', team2Color: 'positive.tint.50' };
  }
  if (team1_percent < team2_percent) {
    return { team1Color: 'positive.tint.50', team2Color: 'positive.main' };
  }
  return { team1Color: 'positive.tint.30', team2Color: 'positive.tint.30' };
};

const WinVizPanel = ({ panelData }) => {
  const [showTeamColors, setShowTeamColors] = useState(false);
  if (!panelData.data || panelData.data.length === 0) {
    return <Box>WinViz unavailable</Box>;
  }

  const data = panelData.data[0];
  const {
    team1_logo,
    team1_abbreviation,
    team1_percent,

    team2_logo,
    team2_abbreviation,
    team2_percent,

    draw_percent,
    tie_percent
  } = data;

  const calcDrawTiePercent = () => {
    const draw = draw_percent ? parseInt(draw_percent) : 0;
    const tie = tie_percent ? parseInt(tie_percent) : 0;
    return draw + tie;
  };
  const { team1Color, team2Color } = calcWVColors(data, showTeamColors);
  return (
    <Flex direction="column" justify="center" h="100%" p="4" gap="2">
      <Flex justifyContent="space-between">
        <Flex alignItems="center" direction="column">
          <Circle border="1px" borderColor="black.8">
            <Image
              boxSize="70px"
              objectFit="contain"
              p={2}
              src={team1_logo}
              onClick={() => setShowTeamColors((p) => !p)}
            />
          </Circle>
          <Box fontWeight="bold" fontSize="lg">
            {team1_abbreviation?.toUpperCase()}
          </Box>
        </Flex>
        <Flex alignItems="center" direction="column">
          <Circle border="1px" borderColor="black.8">
            <Image
              boxSize="70px"
              objectFit="contain"
              p={2}
              src={team2_logo}
              onClick={() => setShowTeamColors((p) => !p)}
            />
          </Circle>
          <Box fontWeight="bold" fontSize="lg">
            {team2_abbreviation?.toUpperCase()}
          </Box>
        </Flex>
      </Flex>
      <Flex
        h="6"
        justifyContent="space-between"
        rounded="full"
        overflow="hidden"
        w="100%"
        bg="black.4"
        p="3px"
        border="1px"
        borderColor="black.8"
      >
        <Box
          w={`${team1_percent}%`}
          // bg={`winviz.${calcWVColour(team1_percent)}`}
          bg={team1Color}
          borderTopStartRadius={10}
          borderBottomStartRadius={10}
        />
        <Tooltip label={`Draw or Tie: ${calcDrawTiePercent()}%`}>
          <Box l={`${team1_percent}%`} w={`${calcDrawTiePercent()}%`} minW="1px" />
        </Tooltip>
        <Box
          w={`${team2_percent}%`}
          // bg={`winviz.${calcWVColour(team2_percent)}`}
          bg={team2Color}
          borderTopEndRadius={10}
          borderBottomEndRadius={10}
          r={`${team2_percent}%`}
        />
      </Flex>
      <Flex justifyContent="space-between" rounded="md" overflow="hidden" w="100%">
        <Flex fontWeight="bold" fontSize="lg" w="72px" justify="center">
          {team1_percent}%
        </Flex>
        <Flex fontWeight="bold" fontSize="lg" w="72px" justify="center">
          {team2_percent}%
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WinVizPanel;
